<template>
  <div class="row justify-content-between h-100">

    <div class="col-lg-10 col-xl-10 my-10">
      <!--::Header Section 
      <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid vh-100">-->
      <div class="card card-custom  mx-10 h-100">
        <div class="card-body">
          <div class="d-flex flex-column px-10 mt-10 col-12 col-xl-8">
            <h1 class="card-label font-weight-bolder mb-5">{{/*$t("LOGINORGANIZATION.COMEFUNZIONA")*/ /* #key_loc */}}</h1>
            <h3 class="text-primary">{{/*$t("LOGINORGANIZATION.CHECOSAE")*/ /* #key_loc */}}</h3>
            <p class="lead mt-5 font-weight-bolder">
              {{/*$t("LOGINORGANIZATION.DESC1")*/ /* #key_loc */}}
            </p>
            <p>
              {{/*$t("LOGINORGANIZATION.DESC2")*/ /* #key_loc */}}
              <br><br>
              {{/*$t("LOGINORGANIZATION.DESC3")*/ /* #key_loc */}}
              <br><br>
              {{/*$t("LOGINORGANIZATION.DESC4")*/ /* #key_loc */}} <br>
              {{/*$t("LOGINORGANIZATION.DESC5")*/ /* #key_loc */}}
              <br><br>
              <b> {{/*$t("LOGINORGANIZATION.DESC6")*/ /* #key_loc */}} </b>
            </p>
          </div>
          <div class="d-flex flex-column">
            <div class="row py-lg-20 py-20  align-items-center">
              <!--::Data Map-->
              <div class="col-xl-5 text-center">
                <div class="pl-lg-10">
                  <iframe width="100%" height="360" src="https://www.youtube.com/embed/4Dg20pA8shE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>	
              <!--::Payoff-->
              <div class="col-xl-7">
                <div class="px-lg-30 px-5 mt-5">
                  <img
                    src="/assets/media/bipart/bg-title-line.png"
                    alt=""
                    class="w-150px"
                  />
                  <h2 class="my-5"><span class="h2 text-primary">{{/*$t("LOGINORGANIZATION.INTRO")*/ /* #key_loc */}} </span></h2>
                  <h4 class="lh-md">{{/*$t("LOGINORGANIZATION.VIDEO")*/ /* #key_loc */}} </h4>
                  <div class="mt-10">
                    <!-- <a href="#" class="btn btn-lg btn-primary font-weight-bolder text-uppercase rounded-0 mr-3">
                      Visualizza
                    </a> -->
                  </div>
                </div>
              </div>
              
            </div>
            <div class="row pb-lg-20 pb-20  align-items-center">
              <!--::Data Map-->
              <div class="col-xl-5 text-center">
                <div class="pl-lg-10">
                  <iframe width="100%" height="360" src="https://www.youtube.com/embed/9ihqAC3NAwI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>	
              <!--::Payoff-->
              <div class="col-xl-7">
                <div class="px-lg-30 px-10 mt-5">
                  <img
                    src="/assets/media/bipart/bg-title-line.png"
                    alt=""
                    class="w-150px"
                  />
                  <h2 class="my-5"><span class="h2 text-primary">{{/*$t("LOGINORGANIZATION.BILANCIO")*/ /* #key_loc */}} </span></h2>
                  <h4 class="lh-md">{{/*$t("LOGINORGANIZATION.DESCBILANCIO")*/ /* #key_loc */}} </h4>
                  <div class="mt-10">
                    <!-- <a href="#" class="btn btn-lg btn-primary font-weight-bolder text-uppercase rounded-0 mr-3">
                      Visualizza
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row pb-lg-20 pb-20  align-items-center">
              <!--::Data Map-->
              <div class="col-xl-5 text-center">
                <div class="pl-lg-10">
                <iframe width="100%" height="360" src="https://www.youtube.com/embed/GkMtWWaFvVg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>	
              <!--::Payoff-->
              <div class="col-xl-7">
                <div class="px-lg-30 px-10 mt-5">
                  <img
                    src="/assets/media/bipart/bg-title-line.png"
                    alt=""
                    class="w-150px"
                  />
                  <h2 class="my-5"><span class="h2 text-primary">{{/*$t("LOGINORGANIZATION.CARTAPART")*/ /* #key_loc */}} </span></h2>
                  <h4 class="lh-md">{{/*$t("LOGINORGANIZATION.CARTAPARTDESC")*/ /* #key_loc */}} </h4>
                  <div class="mt-10">
                    <!-- <a href="#" class="btn btn-lg btn-primary font-weight-bolder text-uppercase rounded-0 mr-3">
                      Visualizza
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row pb-lg-20 pb-20  align-items-center">
              <!--::Data Map-->
              <div class="col-xl-5 text-center">
                <div class="pl-lg-10">
                <iframe width="100%" height="360" src="https://www.youtube.com/embed/SOujH_CCChE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>	
              <!--::Payoff-->
              <div class="col-xl-7">
                <div class="px-lg-30 px-10 mt-5">
                  <img
                    src="/assets/media/bipart/bg-title-line.png"
                    alt=""
                    class="w-150px"
                  />
                  <h2 class="my-5"><span class="h2 text-primary">{{/*$t("LOGINORGANIZATION.FASI")*/ /* #key_loc */}} </span></h2>
                  <h4 class="lh-md">{{/*$t("LOGINORGANIZATION.FASIDESC")*/ /* #key_loc */}} </h4>
                  <div class="mt-10">
                    <!-- <a href="#" class="btn btn-lg btn-primary font-weight-bolder text-uppercase rounded-0 mr-3">
                      Visualizza
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row pb-lg-20 pb-20  align-items-center">
              <!--::Data Map-->
              <div class="col-xl-5 text-center">
                <div class="pl-lg-10">
                <iframe width="100%" height="360" src="https://www.youtube.com/embed/l_nGVAp9dQg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>	
              <!--::Payoff-->
              <div class="col-xl-7">
                <div class="px-lg-30 px-10 mt-5">
                  <img
                    src="/assets/media/bipart/bg-title-line.png"
                    alt=""
                    class="w-150px"
                  />
                  <h2 class="my-5"><span class="h2 text-primary">{{/*$t("LOGINORGANIZATION.ORGANI")*/ /* #key_loc */}} </span></h2>
                  <h4 class="lh-md">{{/*$t("LOGINORGANIZATION.ORGANIDESC")*/ /* #key_loc */}} </h4>
                  <div class="mt-10">
                    <!-- <a href="#" class="btn btn-lg btn-primary font-weight-bolder text-uppercase rounded-0 mr-3">
                      Visualizza
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";

export default {
  name: "login-organization",
  components: {
    //OrganizationMenu
  }
}
</script>

<style></style>
